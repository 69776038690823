import {
  PlusOutlined,
  MoreOutlined,
  ReloadOutlined,
  EditOutlined,
  GroupOutlined,
  UngroupOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Input,
  Modal,
  Space,
  Tag,
  Typography,
  Form,
  Grid,
  Col,
  Row,
  Flex
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { FacultyForm } from '@App/components/forms/FacultyForm';
import { handler } from '@App/settings/ApiHandler';
import { useKeycloak } from '@App/settings/keycloak';
import AppTable from '@App/components/tables/TableComponent';
import DeleteButton from '@App/components/buttons/DeleteButton';

import { DataContext } from '../../AccessView';
import { ApplicationState } from '@App/settings/StateManager';
import { ActionTypes } from '@App/settings/reducers';
import KC from "@App/@types/keycloakTypes";

const tableName = 'facultiesList';

const TextWithPrefix = (text: string, prefix: string) => (
  <Typography.Paragraph style={{ margin: 0 }}>
    <Typography.Text
      strong
      italic
      type='secondary'
      style={{ marginRight: '.5rem' }}
    >
      {prefix.toUpperCase()}
    </Typography.Text>
    {text}
  </Typography.Paragraph>
);

const postFaculty = (
  payload: API.PayloadType,
  kc: KC.KCType,
  code?: string | undefined
) => handler<Responses.Default>({
  method: typeof code == 'string' ? 'PUT' : 'POST',
  path: `/v1/manage/faculty/${code ?? ''}`,
  payload,
}, kc);

export const FacultiesList: React.FC = () => {
  const {state, dispatch} = React.useContext(ApplicationState);
  const {isLoading, faculties, facultiesGrouped, facultyOptions, doRefresh, setLoading} = React.useContext(DataContext);
  
  const [filteredFaculties, setFilteredFaculties] = React.useState<Array<API.FacultyGroupType|API.FacultyItem>>([]);
  const [grouped, setGrouped] = React.useState<boolean>(false);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<'new'|'update'>('new');
  const [search, setSearch] = React.useState('');
  const oldValues = React.useRef<API.FacultyItem | null>(null);
  
  const nav = useNavigate();
  const { keycloak } = useKeycloak();
  const { i18n, t } = useTranslation();
  const [ form ] = Form.useForm();
  const { md } = Grid.useBreakpoint();

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleCancel = () => {
    oldValues.current = null;
    setModalOpen(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearch(e.target.value);
  };

  const handleNewForm = () => {
    oldValues.current = null;
    form.resetFields();
    setAction('new')
    setModalOpen(true);
  };
  
  const handleFacultyUpdate = (code: string) => {
    form.resetFields();
    const foundItem = facultyOptions.find((f) => f.code === code);

    if (foundItem) {
      oldValues.current = foundItem;
      form.setFieldsValue(foundItem);
      setAction('update');
      setModalOpen(true);
    }
  };
  
  const handleFacultyDelete = (code: string) => {
    const foundItem = faculties.find((f) => f.code === code);
    const payload = { action: 'delete' as API.PayloadType['action'], item: foundItem };
    
    postFaculty(
      {action: 'delete'},
      keycloak!,
      code
    ).then((data) => {
      if (data.success) {
        setModalOpen(false);
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const handlePostFaculty = (newValues: API.FacultyItem) => {
    setLoading(true);
    const payload = { action, newValues };

    postFaculty(
      payload,
      keycloak!,
      oldValues?.current?.code
    ).then((data) => {
      if (data.success) {
        setModalOpen(false);
        console.log(' SUCCESS ! ', data.success)
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const postFacultyOld = (newValues: API.FacultyItem) => {
    handler<Responses.Default>({
      method: 'POST',
      path: '/v1/manage/faculties/' + ( action == 'update' ? newValues.code : 'new'),
      payload: { action, newValues },
    }, keycloak!)
      .then((data) => {
        if (data.success) {
          setModalOpen(false);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  React.useEffect(() => {
    if (faculties) {
      const newFilteredList = faculties.filter((f) =>
        f.code.toLowerCase().includes(search.toLowerCase()) ||
        f.name.toLowerCase().includes(search.toLowerCase()) ||
        f.labels.et.toLowerCase().includes(search.toLowerCase()) ||
        f.labels.en.toLowerCase().includes(search.toLowerCase())
      )
      
      newFilteredList.length > 0 && setFilteredFaculties(newFilteredList);
    }
  }, [search, faculties]);

  React.useEffect(() => {
    if ( grouped ) {
      setFilteredFaculties(facultiesGrouped as API.FacultyItem[]);
    } else {
      setFilteredFaculties(faculties);
    }
  }, [grouped]);

  const columns: ColumnsType<API.FacultyItem> = [
    {
      key: 'code',
      // title: t('forms.label.faculty_code', 'Kood'),
      title: t('forms.label.code'),
      dataIndex: 'code',
      defaultSortOrder: 'descend',
      width: grouped ? 120 :80,
      align: 'center',
      responsive: ['sm'],
      sorter: (a, b) => a.code.localeCompare(b.code),
      render: (_, record) => record.category ? (
        <Typography.Text strong style={{
          color: record.parent !== null ? 'inherit' : '#108ee9'
          }}>
          <Tag color={record.parent !== null ? '#108ee9': 'default'}>
            {record.code}
        </Tag>
          </Typography.Text>
      ) : (
        <Tag color={record.parent !== null ? '#108ee9': 'default'}>
          <Typography.Text strong style={{
            color: record.parent !== null ? 'inherit' : '#108ee9'
          }}>
            {record.code}
          </Typography.Text>
        </Tag>
      ),
    },
    {
      key: 'name',
      title: t('forms.label.facultyName'),
      dataIndex: ['name', 'label'],
      width: 'auto',
      sorter: (a, b) => a.labels[i18n.language].localeCompare(b.labels[i18n.language]),
      render: (_, record) => (
        <Typography.Title level={5}>
          { record.labels[i18n.language] ?? record.name}
        </Typography.Title>
      ),
    },
    {
      key: 'users',
      title: t('forms.title.users'),
      dataIndex: 'users',
      responsive: ['sm', 'md'],
      width: 120,
      align: 'center',
      sorter: (a, b) => (a.users?.length ?? 0) - (b.users?.length ?? 0),
      render: (_, record) =>
        record.users && (
          <Typography.Text
            strong={record.users.length > 0}
            type={record.users.length > 0 ? 'success' : 'secondary'}
            style={{
              opacity: record.users.length == 0 ? .4 : 1,
              fontFamily: 'Rubik',
              fontWeight: 400
            }}
          >
            {record.users.length}
          </Typography.Text>
        ),
    },
    {
      key: 'action',
      title: t('forms.label.action'),
      width: md ? 250 : 100,
      align: 'center',
      render: (_, record) => (
        <Space size='small' wrap>
          <Button
            type='link'
            icon={ <ExportOutlined />}
            onClick={()=> nav(`/manage/access/faculties/${record.code}`) }
          >
            { md && t('forms.btn.update')}
          </Button>
          {/* <EditButton type='text' record={record} /> */}
          {/* <Button
            type='text'
            icon={<EditOutlined />}
            onClick={()=> handleFacultyUpdate(record.code) }
          >
            { md && t('forms.btn.update')}
          </Button> */}
          <DeleteButton
            type='text'
            // shape='round'
            item='faculty'
            path={`/v1/manage/faculties/${record.code}`}
            // onDelete={onDelete}
            iconOnly={false}
            record={record}
          />
          {/* <Button
            danger
            type='text'
            shape='round'
            icon={<EditOutlined />}
            onClick={()=> handleFacultyDelete(record.code) }
          >
            { md && t('forms.btn.delete')}
          </Button> */}
       </Space>
      ),
    },
  ];

  const loadTableConfig = () => {
    dispatch({ type: ActionTypes.Load_table, payload: { tableName } });
    console.info({ type: ActionTypes.Load_table }, tableName);
  };

  React.useEffect(() => {
    if (!state.tables[tableName]) {
      loadTableConfig();
    };
  }, []);

  return (
    <>

      <Row justify='space-between' align='bottom' style={{ margin: '1rem 0' }}>
        <Col>
          <Input.Search
            placeholder={t('table.texts.placeholders.searchFaculty')}
            value={search}
            onSearch={handleSearch}
            onChange={handleSearchChange}
          />
        </Col>
        <Col>
          <Button
            shape='round'
            key={"refresh"}
            icon={<ReloadOutlined/>}
            onClick={doRefresh}
          >
            {t('buttons.refresh')}
          </Button>
        </Col>
      </Row>

      <AppTable<API.FacultyItem & {status?: string}>
        rowKey='code'
        loading={isLoading}
        tableName={tableName}
        tableTitle='pages.title.facultiesList'
        counter={grouped ? facultyOptions.length : faculties.length}
        extraColumns={columns}
        dataSource={filteredFaculties}
        isAdmin={false}
        isSelectable={false}
        sticky={{ offsetHeader: 64 }}
        scroll={{ x: 300 }}
        headerButtons={[
          <Button
            key='add-new-faculty'
            shape='round'
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleNewForm}
          >
            {t('forms.btn.add')}
          </Button>,
          <Button
            key='show-grouped'
            shape='circle'
            icon={grouped ? <UngroupOutlined /> : <GroupOutlined />}
            onClick={() => setGrouped(p => !p)}
          />
        ]}
      />

      <Modal
        title={
          <Typography.Title level={3} color='primary'>
            {t(`forms.title.${action}Faculty`)}
          </Typography.Title>
        }
        open={modalOpen}
        // onOk={postFaculty}
        okText={t(`forms.btn.${action === 'new' ? 'create' : 'update'}`)}
        cancelText={t('forms.btn.cancel')}
        confirmLoading={isLoading}
        onCancel={handleCancel}
        width={650}
        footer={null}
      >
        <FacultyForm
          form={form}
          action={action}
          onFinish={handlePostFaculty}
        >
          <Form.Item noStyle>
            <Flex gap='1rem' justify='end' style={{ marginTop: '1rem' }}>
              <Button
                shape='round'
                onClick={() => setModalOpen(false) }
              >
                {t('forms.btn.cancel')}
              </Button>
              <Button
                type='primary'
                shape='round'
                htmlType='submit'
              >
                {t(`forms.btn.${action === 'new' ? 'create' : 'update'}`)}
              </Button>
            </Flex>
          </Form.Item>
        </FacultyForm>
      </Modal>

    </>
  );
};
