import {
  AppstoreOutlined,
  BarsOutlined,
  PlusOutlined,
  ReloadOutlined,
  UserOutlined,
  UnlockOutlined,
  BankOutlined,
  FormOutlined,
  EditOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Grid,
  Menu,
  Row,
  Space,
  Statistic,
  Typography,
} from 'antd';
import { MenuDividerType, MenuItemGroupType, MenuItemType } from "antd/es/menu/interface";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


export const ManagePage: React.FC = () => {
  const nav = useNavigate();
  const {t} = useTranslation('forms');
  const {sm, md, lg} = Grid.useBreakpoint();

  const adminSubPages: Array<MenuItemGroupType | MenuItemType | MenuDividerType> = [
    {
      key: 'data',
      type: 'group',
      label: t('links.fields'),
      children: [
        {
          key: 'all-projects',
          icon: <PlusOutlined />,
          label: t('links.projects'),
          onClick: () => nav('/manage/ttr/projects')
        },
        {
          key: 'all-researches',
          icon: <PlusOutlined />,
          label: t('links.researchProjects'),
          onClick: () => nav('/manage/ttr/researchProjects')
        },
        {
          key: 'all-article30',
          label: t('links.TTRlist'),
          onClick: () => nav('/manage/ttr/oppetoo')
        }
      ],
    },
    {
      key: 'fields',
      type: 'group',
      label: t('links.fields'),
      //@ts-ignore
      count: 40,
      children: [
        {
          key: 'new-field',
          icon: <PlusOutlined />,
          label: t('links.new.field'),
          onClick: () => nav('/manage/forms/fields/new')
        },
        {
          key: 'new-field-2',
          icon: <PlusOutlined />,
          label: t('links.new.field'),
          onClick: () => nav('/manage/forms/fields/newField')
        },
        {
          key: 'all-fields',
          label: t('links.fields'),
          onClick: () => nav('/manage/forms/fields')
        }
      ],
    },
    {
      key: 'forms',
      type: 'group',
      label: t('links.forms'),
      children: [
        {
          key: 'new-form',
          icon: <PlusOutlined />,
          label: t('links.new.form'),
          onClick: () => nav('/manage/forms/builder/new')
        },
        {
          key: 'list-forms',
          label: t('links.forms'),
          onClick: () => nav('/manage/forms/forms')
        },
        {
          key: 'manage-forms',
          label: t('forms.link.formBuilder'),
          onClick: () => nav('/manage/forms/builder')
        }
      ],
    },
    // {
    //   type: 'divider',
    // },
    {
      key: 'users',
      type: 'group',
      label: t('forms.link.users'),
      children: [
        {
          key: 'new-user',
          icon: <PlusOutlined />,
          label: t('forms.link.add.user'),
          onClick: () => nav('/manage/access/users/new')
        },
        {
          key: 'manage-users',
          label: t('forms.link.users'),
          onClick: () => nav('/manage/access/users')
        },
      ],
    },
    {
      key: 'faculties',
      type: 'group',
      label: t('forms.link.faculties'),
      children: [
        {
          key: 'new-faculty',
          icon: <PlusOutlined />,
          label: t('forms.link.add.faculty'),
          onClick: () => nav('/manage/access/faculties/new')
        },
        {
          key: 'manage-faculties',
          label: t('forms.link.faculties'),
          onClick: () => nav('/manage/access/faculties')
        },
      ],
    },
    {
      key: 'groups',
      type: 'group',
      label: t('forms.link.groups'),
      children: [
        {
          key: 'new-group',
          icon: <PlusOutlined />,
          label: t('forms.link.add.group'),
          onClick: () => nav('/manage/access/groups/new')
        },
        {
          key: 'manage-groups',
          label: t('forms.link.groups'),
          onClick: () => nav('/manage/access/groups')
        },
      ],
    },
    {
      key: 'permissions',
      type: 'group',
      label: t('forms.link.permissions'),
      children: [
        {
          key: 'new-permission',
          icon: <PlusOutlined />,
          label: t('forms.link.add.permission'),
          onClick: () => nav('/manage/access/permissions/new')
        },
        {
          key: 'manage-permissions',
          label: t('forms.link.permissions'),
          onClick: () => nav('/manage/access/permissions')
        },
      ],
    },
    {
      key: 'translations',
      type: 'group',
      label: t('forms.link.translations'),
      children: [
        {
          key: 'new-permission',
          icon: <PlusOutlined />,
          label: t('forms.link.add.translation'),
          onClick: () => nav('/manage/translations/new')
        },
        {
          key: 'manage-translations',
          label: t('forms.link.translations'),
          onClick: () => nav('/manage/translations')
        },
      ],
    },
  ]

  // React.useEffect(() => {
  //   if (isMounted.current) {
  //     isMounted.current = false;
  //     getFaculties();
  //   }

  //   return () => {
  //     console.info('unmounted');
  //   };
  // }, []);

  return (
    <div>

      <Row>
        <Col span={20}>
          <Typography.Title level={2}>
            {t('links.manage.page.title')}
          </Typography.Title>
        </Col>
      </Row>
    
      <Row>
        <Col span={20}>

        </Col>
      </Row>
      {
        adminSubPages.map( (group, groupIndex) => (
          <Row key={groupIndex} gutter={16} align='middle'>
            <Divider></Divider>
            <Col span={6}>
              {//@ts-ignore
                group.count &&
                  <Statistic
                      //@ts-ignore
                    title={group.label}
                    //@ts-ignore
                    value={group.count ?? 0}
                  />
              }
            </Col>
            <Col span={18} >
              <Space wrap direction={sm ? 'horizontal' : 'vertical'} style={{width:'100%'}}>
                {//@ts-ignore
                  group.children?.map( (link, linkIdx) => (
                  <Button
                    key={linkIdx}
                    type={link.key.includes('new') ? 'dashed' : 'primary'}
                    onClick={link.onClick}
                  >
                    {link.label}
                  </Button>
                ))}
              </Space>

            </Col>
          </Row>
        ))
      }

      <Row>
        <Col xs={24} md={{span:16, offset:4}}>
          <Menu
            mode="inline"
            defaultSelectedKeys={[]}
            defaultOpenKeys={['sub1']}
            onSelect={console.log}
            items={adminSubPages}
            style={{ height: '100%', borderRight: 0 }}
          />
        </Col>
      </Row>

    </div>
  );
};
