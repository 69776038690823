import { Button, Divider, Form, Input, Space, Spin, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';

import { handler } from '@App/settings/ApiHandler';
import { DividerTitle } from '@App/components/texts/DividerTitle';
import { useKeycloak } from '@App/settings/keycloak';
import KC from "@App/@types/keycloakTypes";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

type PermissionType = {
  id?: string;
  name: string;
  label: string;
  role: string;
};

const getPermissionData = (kc: KC.KCType, id: string|number) => handler<PermissionType>({
  method: 'GET', path: '/v2/manage/permission/' + id,
}, kc);

const postNewPermission = (kc: KC.KCType, payload: API.PayloadType) => handler<PermissionType>({
  method: 'POST', path: '/v2/manage/permission/new', payload
}, kc);
const updateNewPermission = (kc: KC.KCType, payload: API.PayloadType, id:string) => handler<PermissionType>({
  method: 'POST', path: '/v2/manage/permission/'+id, payload
}, kc);

const defaultValues = { name: '', label: '', role: '' };

export const Permission: React.FC = () => {
  const [action, setAction] = React.useState<'new' | 'update'>('new');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [permission, setPermission] = React.useState<PermissionType>(defaultValues);
  const { id } = useParams();
  const [form] = Form.useForm();
  const nav = useNavigate();
  const { keycloak } = useKeycloak();

  const oldValues = React.useRef<PermissionType|null>(null);
  const isMounted = React.useRef(false);

  const getData = () => {
    setIsLoading(true);
    
    id && getPermissionData(keycloak!, id)
      .then( (data) => {
        setAction('update');
        setPermission(data);
        oldValues.current = data;
        form.setFieldsValue(data);
      })
      .finally( () => {
        setIsLoading(false)
      });
  };
  
  const onFinish = (values: PermissionType) => {
    if (action == 'new') {
      postNewPermission(keycloak!, { action, ...values })
    }
    else {
      updateNewPermission(keycloak!, { action, ...values }, id!)
    }
  };

  const onReset = () => {};
  const onDelete = () => {};
  
  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      if ( id && !['new','add','create'].includes(id) ) {
        getData();
      }
    }

    return () => {
      console.info('unmounted');
    };
  }, []);
  
  return (
    <div>

      <DividerTitle
        title={(action === 'new' ? 'Create new' : 'Update') + ' permission'}
        orientation="center"
      />

      <Spin spinning={isLoading}>
        <Form
          {...layout}
          form={form}
          name={action === 'new' ? 'Add new user' : 'Update user'}
          onFinish={onFinish}
          layout='horizontal'
          initialValues={permission}
          style={{ maxWidth: 600 }}
        >
          <Form.Item name='name' label='Name' rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name='label' label='Label' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          
          <Form.Item name='role' label='Role' rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Space.Compact block>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
              <Button htmlType='button' onClick={onReset}>
                Reset
              </Button>
              {
                action === 'update' && (
                  <Button danger htmlType='button' onClick={onDelete}>
                    delete
                  </Button>
                )
              }
            </Space.Compact>
          </Form.Item>

        </Form>
      </Spin>
{/* 
      <JsonValidator
        path={`/v2/manage/permissions/${action}`}
        action={action}
      /> */}

    </div>
  );
};
