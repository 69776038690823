import type { ColumnsType, ColumnType, TablePaginationConfig } from 'antd/es/table';
import type { ColumnFilterItem, FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Button, Checkbox, Col, Dropdown, Input, Menu, Row, Space, Table, Tag, Typography } from 'antd';
import { DownSquareFilled, RightSquareOutlined, ReloadOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React from 'react';

import { formatDate } from '@App/components/helpers';
import { statusItems } from '@App/components/buttons/StatusButton';
import { ApplicationState } from '@App/settings/StateManager';
import { ActionTypes } from '@App/settings/reducers';

import { ExpandRowContent } from './ExpandableRow';


type FindFacultyByIdOrCodeType = (
  faculties: Array<Responses.FacultyType | API.FacultyItem>,
  codeOrID: Responses.FacultyType["id"] | (Responses.FacultyType|API.FacultyItem)["code"]
) => Responses.FacultyType | API.FacultyItem | undefined;

const findFacultyById: FindFacultyByIdOrCodeType = (faculties, codeOrID) => {
  return faculties.find(faculty => {
    if ('id' in faculty) return faculty.id === Number(codeOrID)
    else return faculty.code === codeOrID;
  });
}

const pageSizeOptions = [10, 50, 150];

interface ExpList {
  isLoading: boolean;
  head: Form.FormType | API.Form;
  listItems: any[];
  hiddenColumns: string[];
  formFields?: API.Field[] | Form.FormType['fields'] | API.Form['fields'];
  faculties: Array<Responses.FacultyType | API.FacultyItem>;
  onUpdate: ( record: Responses.TTRData) => void
}

const tableName = "art30Table"; // Name of your table

const ExpandableList: React.FC<ExpList> = (props) => {
  // const [itemsPerPage, setItemsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [formFields, setFormFields] = React.useState<Array<API.Field>>(props.formFields ?? []);
  const { state, dispatch } = React.useContext(ApplicationState);
  // const [ filteredData, setFilteredData ] = React.useState<Responses.TTRData[]>([]);
  
  // const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(
  //   state.tables?.[tableName]?.hiddenColumns ?? []
  // );
  const { t, i18n } = useTranslation();
  const nav = useNavigate();

  const handlePageSizeChange = (currentSize: number, size: number) => {
    updateTableConfig({
      ...state.tables?.[tableName],
      pageSize: size
    })
  };

  const handleUserDeleteCB = () => {};

  // const handleColumnToggle = (e: any, columnName: string) => {
    
  //   setHiddenColumns(prev => {
  //     if (e.target.checked) {
  //       return [...prev, columnName];
  //     } else {
  //       return prev.filter(name => name !== columnName);
  //     }
  //   });
  //   // updateTableConfig({
  //   //   ...state.tables[tableName], hiddenColumns
  //   // })
  // };
  
  // React.useEffect(()=>{
  //   updateTableConfig({
  //     ...state.tables[tableName],
  //     hiddenColumns
  //   })
  // },[hiddenColumns])
  
  // const menu = (
  //   <Menu>
  //     {formFields.map((field) => (
  //       <Menu.Item key={field.name}>
  //         <Checkbox
  //           checked={hiddenColumns?.includes(field.name)}
  //           onChange={(e) => handleColumnToggle(e, field.name)}
  //         >
  //           {field.label}
  //         </Checkbox>
  //       </Menu.Item>
  //     ))}
  //   </Menu>
  // );
  type OptionsFilters = {
    onFilter: (value: string | number, record: Responses.TTRData) => boolean;
    filters: ColumnFilterItem[]
  }

  const fieldColumns  = formFields
    .filter(field => !props.hiddenColumns?.includes(field.name))
    .map((field): ColumnType<Responses.TTRData> => {
      const missingStrValues = ['puuduvad','puudub', undefined];
      let filterProps: OptionsFilters = {
        onFilter: (value, record) => {
          //@ts-ignore
          if ( record.project_data && field.name in record.project_data) {
          //@ts-ignore
            return record.project_data[field.name].includes(value+'');
          }
          return false;
        },
        filters: []
      }
      if (field.type.includes('select') && field.settings.options) {
        field.settings.options.map((v) =>
          filterProps['filters'].push({
            text: v.label,
            value: v.value ?? v.label
          })
        )
      }
      
      return {
        key: field.name,
        title: field.label,
        dataIndex: [ 'project_data', field.name ],
        width: 300,
        filtered: props.hiddenColumns?.includes(field.name) ?? true,
        ...(filterProps.filters.length > 0 ? {filterProps} : {}),
        render: (v, record, rowIdx: number) => {
          if (Array.isArray(v)) {
            return v.map( (vi, idx) => (
              <Typography.Text key={`${rowIdx}-${idx}`} type={missingStrValues.includes(vi) ? 'danger' : undefined}>
                {vi}
                { v.length !== idx+1 ? <br/> : '' }

                {/* {
                  v.length == idx+1 ? '.' // last item
                  : v.length == idx+2 ? ' ja ' // eelviimane item
                  : v.length == 1 ? '' // if just 1 item
                  : v.length > 1 ? <>,<br/></>
                  : <> ja <br/></>
                } */}
              </Typography.Text>
            ));
          } else return (
            <Typography.Text type={missingStrValues.includes(v) ? 'danger' : undefined}>
              {v}
            </Typography.Text>
        )},
      }
    });

  
  const fieldFilters = props.formFields?.map( field => ({
      key: field.name,
      text: field.label,
      value: field.id,
    })
  ) ?? [];
  const facultyFilters = props.faculties.filter( (f) => !f.category )
    .map( faculty => ({
        key: faculty.code,
        text: ` ${faculty.code} ${faculty.labels[i18n.language as 'et'|'en']} `,
        value: faculty.code,
      })
    ) ?? [];

  const columns: ColumnsType<Responses.TTRData> = [
    {
      key: 'faculty_id',
      title: t('table.column.label.faculty'),
      dataIndex: 'faculty_id',
      filters: facultyFilters,
      filterSearch: true,
      onFilter: (value, record) => record.faculty_code == value,
      sorter: {
        compare: (a, b) => a.faculty_id - b.faculty_id,
        multiple: 1,
      },
      // fixed: 'left',
      width: 100,
      render: (fID: number|string, r) => {
        // Convert stringified numbers back to number if applicable
        const facultyId = (typeof r.faculty_id === 'string' && !isNaN(r.faculty_id)) 
          ? Number(fID) 
          : fID;
        
        if (props.faculties) {
          const itemFaculty = findFacultyById(props.faculties, facultyId);
            if (itemFaculty) {
              return (
                <Tag>
                  <Typography.Text
                    color={'#2c5696'}
                    title={itemFaculty.labels[i18n.language as 'en'|'et']}
                  >
                    {itemFaculty.code ?? itemFaculty.labels[i18n.language as 'en'|'et']}
                  </Typography.Text>
                </Tag>
              )
            }
        }
        return r.faculty_code ?? fID;
      }
    },
    ...fieldColumns,
    // {
    //   key: 'fields',
    //   title: t('table.column.formFields'),
    //   dataIndex: 'project_data',
    //   align: 'left',
    //   filters: fieldFilters,
    //   // filterSearch: true,
    //   onFilter: (value, record) => {
    //     return !!(record.project_data && typeof value == 'string' && value in record.project_data);
    //   },
    //   width: '80%',
    //   children: fieldColumns,
    // },
    // {
    //   key: 'dates',
    //   title: t('table.column.entityDates'),
    //   dataIndex: 'created_at',
      
    //   sorter: {
    //     compare: (a, b) => a.faculty_id - b.faculty_id,
    //     multiple: 1,
    //   },
    //   children: [
    //   ]
    // },
    {
      sorter: {
        compare: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
        multiple: 1,
      },
      title: t('table.column.created'),
      dataIndex: 'created_at',
      key: 'created_at',
      width: 140,
      render: (createdAt) => formatDate(createdAt),
    },
    {
      sorter: {
        compare: (a, b) => new Date(a.updated_at || 0).getTime() - new Date(b.updated_at || 0).getTime(),
        multiple: 2,
      },
      title: t('table.column.lastUpdated'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 140,
      render: (updatedAt) => formatDate(updatedAt)
    },
    {
      key: 'status',
      title: t('table.column.label.status'),
      dataIndex: 'status',
      filters: statusItems.map(
        (i, idx) => ({ text: i.label, value: i?.key})
      ),
      // filterMode: 'tree',
      width: 100,
      onFilter: (value, record) => record.status == value,
      render: (status) => {
        const color =
          status === 'draft' ? 'gold'
          : status === 'active'? 'green'
          : status === 'deleted' ? 'red'
          : 'cyan';
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },
    {
      key: 'version',
      title: t('table.column.label.version'),
      dataIndex: 'version',
      align: 'center',
      width: 100,
      sorter: {
        compare: (a, b) => a.version - b.version,
        multiple: 3,
      },
    },

  ];

  const expandIconStyles = {
    fontSize: '1.4rem'
  };
  
  const tablePaginatorConfig: TablePaginationConfig= {
    // total: filteredData.length,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    // defaultPageSize: itemsPerPage,
    // defaultCurrent: currentPage,        
    showSizeChanger: true,
    current: currentPage,
    pageSize: state.tables?.[tableName]?.pageSize ?? 50,
    onShowSizeChange: handlePageSizeChange,
    onChange: setCurrentPage,
    pageSizeOptions,
    style: { marginTop: '1rem', textAlign: 'right' }
  };
  
  const updateTableConfig = (data: App.TableMeta) => {
    dispatch({ type: ActionTypes.Update_table, payload: { tableName, data } });
  };


  React.useEffect(()=> {
    if (props.formFields) {
      setFormFields(props.formFields);
    }
  }, [props.formFields]);

  React.useEffect(() => {
    if (!state.tables?.[tableName]) {
      dispatch({ type: ActionTypes.Load_table, payload: { tableName } });
    }
  }, [tableName]);


  return (
    <>
      <Table
        rowKey='uuid'
        columns={columns}
        loading={props.isLoading}
        dataSource={props.listItems}
        pagination={tablePaginatorConfig}
        scroll={{ x: 1024, y: 600 }}
        locale={{
          emptyText: t('table.texts.emptyText'),
          filterConfirm: t('table.texts.filterConfirm'),
          filterReset: t('table.texts.filterReset'),
          filterSearchPlaceholder: t('table.texts.placeholders.filterFaculty'),
        }}
        expandable={{
          rowExpandable: (record) => record.project_data && Object.keys(record.project_data).length > 0,
          expandedRowRender: (record) => (
            <ExpandRowContent
              key={record.uuid}
              record={record}
              onEdit={props.onUpdate}
              formFields={formFields}
            />
            ),
          expandIcon: ({expanded, onExpand, record}) => expanded
          ? <DownSquareFilled onClick={(e)=> onExpand(record, e)} style={expandIconStyles} />
          : <RightSquareOutlined onClick={(e)=> onExpand(record, e)} style={expandIconStyles} />
        }}
        style={{ maxWidth: '100%'}}
      />
    </>
  );
}

export default ExpandableList;
