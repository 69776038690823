import { App, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { ApplicationState } from './settings/StateManager';
import { ActionTypes } from './settings/reducers';
import { darkThemeColors, lightThemeColors} from "./themeStyles";
import { handler } from './settings/ApiHandler';
import { useKeycloak } from './settings/keycloak';
import UIRoutes from './settings/router';

import et from 'antd/locale/et_EE';
import en from 'antd/locale/en_GB';

// date handlers translations
import 'dayjs/locale/et';
import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import {LoadingPage} from "./views/LoadingPage";

// Here start we load the translation module for
// import "./i18n";

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(duration);

/**
 * Here is defined entire applications supported languages
 */
export enum Language {
  EN = "en",
  ET = "et",
}
export enum PossibleLanguage {
  DE = 'de',
  RU = 'ru'
}

/**
 * Here is defined applications supported theme(s) mode
 */
export enum Theme {
  Auto = "auto",
  Dark = "dark",
  Light = "light",
}

export const Application: React.FC = () => {
  const { i18n } = useTranslation();
  const { keycloak } = useKeycloak();
  const { state, dispatch } = React.useContext(ApplicationState);

  const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  
  const getThemeValue = () => {
    if (
      state.theme === Theme.Auto && prefersDarkMode ||
      state.theme === Theme.Dark
    ) return darkThemeColors;
    return lightThemeColors;
  };

  const getUserData = () => {
    const abortController = new AbortController();
    const payload: API.GetRequest = {
      method: 'GET',
      path: '/v2/user',
      signal: abortController.signal
    }

    handler<API.UserType>(payload, keycloak!)
    .then((success) => {
      const ldapGroups = keycloak?.tokenParsed?.groups || [];
      const faculties: Array<API.FacultyItem> = [ ...(success.faculties ?? []) ];
      const permissions: Array<string> = success.permissions ?? [];
      const roles: Array<string|API.GroupType> = [ ...(success.roles ?? []) ];
      const groups: Array<string> = [ ...(ldapGroups ?? []), ...(success.groups ?? []) ];

      dispatch({
        type: ActionTypes.Update_user,
        payload: {
          ...state.session,
          roles,
          groups,
          faculties,
          permissions,
        }
      })
    })
    .catch((error) => {
        console.log(error);
      // if (error.name !== 'AbortError') {
      //   console.log(error);
      // }
    });

    return () => {
      abortController.abort();
    };
  };
  
  /**
   * When application is mounted get from backend current
   * logged in user's profile data, like faculties, permissions.
   */
  React.useEffect(() => {
    const cleanup = getUserData();

    return () => {
      cleanup();
    };
  }, [keycloak]);
  
  /**
   * Whenever clients state language value changes and state language  update
   * entire application language lib handlers lang.
   */
  React.useEffect(() => {
    if (state.language !== i18n.language) {
      i18n.changeLanguage(state.language);
    }

    dayjs.locale(i18n.language == 'et' ? 'et' : 'en-gb');
  }, [state.language]);

  return (
    <div className={`theme-${state.theme}`}>
      <ConfigProvider
        theme={getThemeValue()}
        locale={i18n.language == 'et' ? et : en}
      >
        <App>
          <UIRoutes />
        </App>
      </ConfigProvider>
    </div>
  );
};
