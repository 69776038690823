import {
  AppstoreOutlined, BarsOutlined, ReloadOutlined, PlusOutlined, ArrowLeftOutlined
} from '@ant-design/icons';
import { Outlet, useLocation, useNavigate, useRouteError } from 'react-router-dom';
import { Button, Col, Divider, Grid, Result, Row, Segmented, Typography } from 'antd';
import React from 'react';

import { handler } from '@App/settings/ApiHandler';
import { useKeycloak } from '@App/settings/keycloak';
import PageWrapper from '@App/components/wrappers/PageWrapper';
import { useTranslation } from 'react-i18next';

type FormError = {
  type: 'loading' | 'maintenance' | 'network' | 'other';
  message: string;
};

export const FormsErrorPage: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [updateTime, setUpdateTime] = React.useState<number | null>(null);

  const { t } = useTranslation();
  const error = useRouteError() as FormError;
  const nav = useNavigate();

  let content;
  switch (error.type) {
    case 'maintenance':
      content = (
        <Result
          status="warning"
          title="Maintenance Mode"
          subTitle={error.message}
          extra={[
            // Maintenance mode specific actions
          ]}
        />
      );
      break;
    case 'loading':
      content = (
        <Result
          status="error"
          title="Form Loading Failed"
          subTitle={error.message}
          extra={[
            <Button type="primary" key="back" onClick={() => nav("/forms")}>
              {t('Back to Forms')}
            </Button>,
            // Other actions for loading error
          ]}
        />
      );
      break;
    // Handle other cases...
    default:
      content = (
        <Result
          status="error"
          title="An Unexpected Error Occurred"
          subTitle={error.message}
          // Default actions...
        />
      );
  }

  return (
    <PageWrapper>
      <Row justify='center' style={{maxWidth:991}}>
        <Col span={24}>
          {content}
        </Col>
      </Row>
    </PageWrapper>
  );
};
