import { PlusOutlined, ReloadOutlined, NumberOutlined } from '@ant-design/icons';
import {
  Avatar, Button, Space, Typography, Input,
  Modal, Form, Row, Col, Grid
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React from 'react';

import { ApplicationState } from '@App/settings/StateManager';
import { ActionTypes } from '@App/settings/reducers';
import { handler } from '@App/settings/ApiHandler';
import { useKeycloak } from '@App/settings/keycloak';
import { GroupForm } from '@App/components/forms/GroupForm';
import AppTable from '@App/components/tables/TableComponent';
import DeleteButton from '@App/components/buttons/DeleteButton';
import EditButton from '@App/components/buttons/EditButton';

import { DataContext } from '../../AccessView';
import KC from "@App/@types/keycloakTypes";


const tableName = 'groupsList';

type GroupResponse = Responses.Default & {};

const postGroup = (
  kc: KC.KCType,
  payload: API.PayloadType,
  item?: number | string | undefined
) => handler<GroupResponse>({
  method: Boolean(item) ? 'PUT' : 'POST',
  path: '/v1/manage/group/' + (item ?? ''),
  payload
}, kc);

export const GroupList: React.FC = () => {
  const {state, dispatch} = React.useContext(ApplicationState);
  const { groups, permissions, post, doRefresh, isLoading, setLoading} = React.useContext(DataContext);
 
  const [filteredGroups, setFilteredGroups] = React.useState<Array<Responses.PermissionGroupType|API.GroupType>>([]);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<'new'|'update'>('new');
  const [search, setSearch] = React.useState<string>('');
  const oldValues = React.useRef<API.GroupType | Responses.PermissionGroupType | null>();

  const [form] = Form.useForm();
  const { md, lg } = Grid.useBreakpoint();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const nav = useNavigate();
 
  const handleCancel = () => {
    setModalOpen(false);
    // selectedUser.current = undefined;
  };

  const handlePost = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // setIsLoading(true);
    const newValues = form.getFieldsValue();

    form.validateFields().then(() => {
      const payload = {action, newValues};

      postGroup(keycloak!, payload, oldValues.current?.id)
      .then((res) => {
        setModalOpen(false);
        if (res.success) {
          doRefresh();
        }
      })
    });
  };

  const handleFormPost = (newValues: any) => {
    setLoading(true);
    const payload = {action, newValues};
    
    postGroup(keycloak!, payload, oldValues.current?.id)
    .then((res) => {
      setModalOpen(false);
      if (res.success) {
        doRefresh();
      }
    }).finally(()=>{
      setLoading(false)
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearch(e.target.value);
  };

  const handleGroupUpdate = (id: string | number | undefined) => {
    // selectedUser.current = uuid;
    const fillValues = groups.find(u => u.id == id);
    if (fillValues) {
      oldValues.current = fillValues;
      form.setFieldsValue(fillValues);
      setAction('update');
      setModalOpen(true);
    }
  };

  const handleNewForm = () => {
    form.resetFields();
    setAction('new')
    setModalOpen(true);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  React.useEffect(() => {
    if (groups) {
      const newFilteredList = groups.filter((group) =>
        group.name.toLowerCase().includes(search.toLowerCase()) ||
        group.label.toLowerCase().includes(search.toLowerCase()) ||
        group.description.toLowerCase().includes(search.toLowerCase())
      )
      setFilteredGroups(newFilteredList ?? []);
    }
  }, [search, groups]);


  const columns: ColumnsType<Responses.PermissionGroupType|API.GroupType> = [
    {
      key: '#',
      title: <NumberOutlined />,
      width: 40,
      render: (name, record, index: number) => (
        <Avatar size='small'>{index+1}</Avatar>
      ),
    },
    {
      key: 'name',
      title: t('table.column.label.groupName'),
      dataIndex: 'name',
      width: 'auto',
      render: (name, record, index: number) => (
        <>
          <Typography.Text strong>{record.label}</Typography.Text>
          <Typography.Paragraph type='secondary'>{record.description}</Typography.Paragraph>
        </>
      ),
    },
    {
      key: 'permissions',
      title: t('table.column.label.permissions'),
      dataIndex: 'permissions',
      responsive: ['sm'],
      width: 140,
      align:'center',
      sorter: {
        compare: (a, b) => (a.permissions?.length?? 0) - (b.permissions?.length ?? 0),
        multiple: 1,
      },
      render: (gPermissions, record) => (
        <Typography.Text
          strong={gPermissions.length > 0}
          type={gPermissions.length > 0 ? 'success' : 'secondary'}
          style={{ opacity: gPermissions.length == 0 ? .4 : 1 }}
        >
          {gPermissions.length}
        </Typography.Text>
      ),
    },
    {
      key: 'faculties',
      title: t('table.column.label.faculties'),
      dataIndex: 'faculties',
      responsive: ['sm'],
      width: 120,
      align:'center',
      sorter: {
        compare: (a, b) => (a.faculties?.length?? 0) - (b.faculties?.length ?? 0),
        multiple: 2,
      },
      render: (gFaculties, record) => (
        <Typography.Text
          strong={gFaculties.length > 0}
          type={gFaculties.length > 0 ? 'success' : 'secondary'}
          style={{ opacity: gFaculties.length == 0 ? .4 : 1 }}
        >
          {gFaculties.length}
        </Typography.Text>
      ),
    },
    {
      key: 'users',
      title: t('table.column.label.users'),
      dataIndex: 'users',
      responsive: ['sm'],
      width: 120,
      align:'center',
      sorter: {
        compare: (a, b) => (a.users?.length?? 0) - (b.users?.length ?? 0),
        multiple: 3,
      },
      render: (gUsers, record) => (
        <Typography.Text
          strong={gUsers.length > 0}
          type={gUsers.length > 0 ? 'success' : 'secondary'}
          style={{ opacity: gUsers.length == 0 ? .4 : 1 }}
        >
          {gUsers.length}
        </Typography.Text>
      ),
    },
    {
      key: 'action',
      title: t('table.column.label.action'),
      // fixed: 'right',
      align: 'center',
      width: md ? 250 : 100,
      render: (_, record) => (
        <Space direction='horizontal' size='small' wrap>
          <EditButton
            // shape='round'
            type='text'
            record={record} 
            iconOnly={lg}
            text={t('forms.btn.update')}
            onClick={() => handleGroupUpdate(record.id)}
          />
          <DeleteButton
            iconOnly={!lg}
            item='group'
            type='text'
            // shape='round'
            path={`/manage/access/groups${record.id}`}
            record={record}
          />
        </Space>
      ),
    },
  ];
  
  const loadTableConfig = () => {
    dispatch({ type: ActionTypes.Load_table, payload: { tableName } });
    console.info({ type: ActionTypes.Load_table }, tableName);
  };

  React.useEffect(() => {
    if (!state.tables[tableName]) {
      loadTableConfig();
    };
  }, []);

  return (
    <>
      <Row justify='space-between' style={{ margin: '1rem 0' }}>
        <Col>
          <Input.Search
            placeholder={t('table.texts.placeholders.searchGroup')}
            onSearch={handleSearch}
            onChange={handleSearchChange}
            // prefix={<SearchOutlined />}
          />
        </Col>
        <Col>
          <Button
            shape='round'
            key={"refresh"}
            icon={<ReloadOutlined/>}
            onClick={doRefresh}
          >
            { md && t('buttons.refresh')}
          </Button>
        </Col>
      </Row>

      <AppTable<Responses.PermissionGroupType & {status?:string} | API.GroupType & {status?:string}>
        rowKey='id'
        loading={isLoading}
        extraColumns={columns}
        scroll={{ x: 300 }}
        dataSource={filteredGroups}
        isAdmin={false}
        isSelectable={false}
        tableName={tableName}
        tableTitle={'pages.title.groupsList'}
        counter={groups.length}
        sticky={{ offsetHeader: 64 }}
        headerButtons={[
          <Button
            key='add-new-group'
            shape='round'
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleNewForm}
          >
            {t('forms.btn.add')}
          </Button>
        ]}
      />

      <Modal
        title={
          <Typography.Title level={3} color='primary'>
            {t(`forms.title.${action}Group`)}
          </Typography.Title>
        }
        open={modalOpen}
        onOk={handlePost}
        // okText={action === 'new' ? 'Create' : 'Update'}
        okText={t(`forms.btn.${action === 'new' ? 'create' : 'update'}`)}
        cancelText={t('forms.btn.cancel')}
        confirmLoading={isLoading}
        onCancel={handleCancel}
        // width={800}
      >
        <GroupForm
          form={form}
          // permissions={permissions}
          action={action}
          onFinish={handleFormPost}
        />
      </Modal>

    </>
  );
};
