import React from 'react';
import {
  EditOutlined,
  UserOutlined,
  ReloadOutlined,
  ClockCircleFilled
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Descriptions,
  Space,
  Tag,
  theme,
  Tooltip,
  Typography
} from 'antd';
import { formatDate, formatDateTime } from '@App/components/helpers';
import { useTranslation } from 'react-i18next';
import { renderValue, stringToColor } from './TableHelpers';
import { ApplicationState } from '@App/settings/StateManager';


interface ExpandRowContentProps {
  record: Responses.TTRData;
  formFields?: API.Field[];
  className?: string;
  isAdmin?: boolean;
  onEdit: (record: Responses.TTRData) => void;
  onAdminEdit?: (record: Responses.TTRData) => void;
  onDelete?: (e:object) => void;
}

export const ExpandRowContent: React.FC<ExpandRowContentProps> = ({
  record, onEdit, onDelete, onAdminEdit, formFields, className, isAdmin = false
}) => {
  const {state, dispatch} = React.useContext(ApplicationState);
  const { t } = useTranslation();
  const { token } = theme.useToken();

  // Safely handle if project_data is null or undefined
  if (!('project_data' in record || 'form_data' in record)) return <p>No data available</p>;

  const handleEditClick = () => {
    // const formName = record.project_data && 'formName' in record.project_data ?  record.project_data['formName']+'' : 'Form not found';
    onEdit( record );
  };

  const handleAdminEditClick = () => {
    // const formName = record.project_data && 'formName' in record.project_data ?  record.project_data['formName']+'' : 'Form not found';
    onAdminEdit && onAdminEdit( record );
  };

  return (
    <div
      key={record.uuid}
      className={className}
      style={{
        display: 'block',
        position: 'sticky',
        width: state.isSidebarCollapsed ? '82vw' : '64vw',
        left: 0,
      }}
    >

      <Space direction='vertical' style={{ padding: '0 1.5rem .5rem',width: '100%'}}>

        <Space style={{ justifyContent: 'space-between' }}>
          <Typography.Paragraph>
            {record.form_data?.faculty_code}
          </Typography.Paragraph>
          <Tag icon={record.updated_at ? <ReloadOutlined /> : <ClockCircleFilled />}>
            {formatDateTime(record.updated_at ? record.updated_at : record.created_at)}
          </Tag>
        </Space>

        <Card>
        <Descriptions
          layout='vertical'
          column={1}
          // extra={[
          //   <Button onClick={handleSave}>muuda</Button>,
          //   <Button danger onClick={handleSave}>kustuta</Button>
          // ]}
          style={{ width: '100%' }}
          labelStyle={{
            fontWeight: 600,
            color: token.colorText
          }}
        >
          {/* <Descriptions.Item label={t('forms.label.formName')}>
            <Typography.Title style={{ marginLeft: 8 }}>
              { 'formName' in record.project_data ? record.project_data['formName'] +'' : "--" }
            </Typography.Title>
          </Descriptions.Item> */}

          {
            Object.entries(
              // 'project_data' in record && record.project_data ||
              'form_data' in record && record.form_data
            ).map(([key, value]) => (
              <Descriptions.Item
                key={key}
                label={formFields?.find(i => i.name == key)?.label ?? t(`forms.label.${key}`)}
                labelStyle={{fontStyle: 'italic'}}
              >
                <Typography.Paragraph style={{ marginLeft: 8 }}>
                  {renderValue(value, '', false)}
                </Typography.Paragraph>
              </Descriptions.Item>
            ))
          }
        </Descriptions>
        </Card>

        {/* <Divider/> */}
        
        <Descriptions layout='vertical' colon column={2}>

          <Descriptions.Item label={t('table.column.label.createdAt')}>
            <Typography.Paragraph style={{ marginLeft: 8 }}>
              { formatDate(record.created_at) }
            </Typography.Paragraph>
          </Descriptions.Item>

          <Descriptions.Item label={t('table.column.lastUpdated')}>
            <Typography.Paragraph style={{ marginLeft: 8 }}>
              { formatDate(record.updated_at) }
            </Typography.Paragraph>
          </Descriptions.Item>
          {
            isAdmin && (
              <>
                <Descriptions.Item label={t('table.column.label.version')}>
                  <Typography.Text style={{ marginLeft: 8 }}>
                    { record.version }
                  </Typography.Text>
                </Descriptions.Item>
                
                <Descriptions.Item label={t('forms.label.editors')} contentStyle={{ marginLeft: 8 }}>
                  <Avatar.Group >
                    {
                      Array.isArray(record.editors)
                      ? record.editors.map( (u,i) => (
                        <Tooltip key={i} title={u} placement="top">
                          <Avatar style={{ backgroundColor: stringToColor(u) ?? '#87d068' }} icon={<UserOutlined />} />
                        </Tooltip>
                      ))
                      : record.editors
                    }
                  </Avatar.Group>
                </Descriptions.Item>
              </>
            )
          }

        </Descriptions>
        
        <Space>
          <Button
            type='primary'
            icon={<EditOutlined />}
            onClick={handleEditClick}
          >
            {t('forms.btn.update')}
          </Button>

          {/* <DeleteButton
            item='entry'
            iconOnly={false}
            path={`/v3/ttr/${record.uuid}/delete`}
            onDelete={onDelete}
            record={record}
          /> */}
          {
            isAdmin && (
              <>
              {/* <Button
                type='primary'
                icon={<EditOutlined />}
                onClick={handleEditClick}
              >
                {t('forms.btn.setFilled')}
              </Button> */}
              <Button
                type='default'
                icon={<EditOutlined />}
                onClick={handleAdminEditClick}
              >
                {t('forms.btn.setFilled')}
              </Button>
              {/* <StatusDropdown initialValue={record.status} onChange={function (newValue: string): void {
                  throw new Error('Function not implemented.');
                } } /> */}
              </>
            )
          }
        </Space>

      </Space>
    </div>
  );
};
